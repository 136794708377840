<script setup lang="ts">
import type { Ref } from "vue";
import VProductDetails from "@magnit/unit-product-details/src/components/VProductDetails.vue";
import VSkeletonProductDetails from "@magnit/unit-product-details/src/components/skeleton/VSkeletonProductDetails.vue";
import { type ICatalogBFFDetailedGood } from "@magnit/unit-product-details/src/types/goods";
import { type IPromoProduct } from "@magnit/unit-catalog/src/types";
import { STATE_PRODUCT_DETAIL } from "~/constants/state";

const route = useRoute();
const { requestProductById } = goodsApi();

const productId = computed(() => (route.params.product ?? "") as string);

const { data, pending } = await requestProductById(productId.value);

const productDetailState = useState(
  STATE_PRODUCT_DETAIL,
  (): Ref<ICatalogBFFDetailedGood | IPromoProduct | null> => ref(null),
);
productDetailState.value = data.value;

onUnmounted(() => (productDetailState.value = null));

const storesStore = useStoresStore();
const seoTitle = computed(() =>
  data.value?.name
    ? `${data.value.name} — в каталоге на сайте В1 | ${storesStore.selectedStore.city}`
    : "",
);
const seoDescription = computed(() =>
  data.value?.name
    ? `${data.value.name} — смотреть цену в каталоге на официальном сайте сети В1 в городе ${storesStore.selectedStore.city}`
    : "",
);

useSeoMeta({
  title: seoTitle,
  ogTitle: seoTitle,
  description: seoDescription,
  ogDescription: seoDescription,
});

useHead({
  link: [
    {
      rel: "canonical",
      href: route.path,
    },
  ],
});
</script>

<template>
  <VWrapperLocal>
    <VSkeletonProductDetails v-if="pending" />
    <template v-if="data">
      <VProductDetailsHeader :product="data" />
      <VProductDetails :product="data" category-name="" />
    </template>
  </VWrapperLocal>
</template>
