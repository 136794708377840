<script setup lang="ts">
import type { IProductDetailsBase } from "@magnit/unit-product-details/src/types/product-details";
import VInlineButton from "@magnit/core/src/components/VInlineButton/VInlineButton.vue";
import ShareIcon from "@magnit/icons/src/assets/icons/share-24.svg";
import { LABEL_CATALOG_MAIN_PAGE } from "~/constants/catalog";

const props = defineProps<IProductDetailsBase>();
const productComp = computed(() => props.product);
const { productCurrentCategory } = useProduct(productComp);
const backRoute = computed(
  () => productCurrentCategory.value?.url ?? Routes.Main,
);

const { open } = useShareModal();

const onBtnClick = () => open();
</script>

<template>
  <section class="product-details-header">
    <VCatalogBack
      :route="backRoute"
      :text="LABEL_CATALOG_MAIN_PAGE"
      class="product-details-header__backlink"
    />
    <VShare class="product-details-header__share">
      <VInlineButton
        theme="secondary"
        class="product-details-share"
        @click="onBtnClick"
      >
        <ShareIcon />
      </VInlineButton>
    </VShare>
  </section>
</template>

<style lang="postcss" scoped>
.product-details-header {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  padding: 4px 0;
  height: 48px;

  &__breadcrumbs {
    flex-basis: 100vw;
    margin: 0 -12px;

    @media (--pl-viewport-from-s) {
      margin: 0 -20px;
    }

    @media (--pl-viewport-from-ml) {
      margin: 0 -32px;
    }
  }
}
</style>
