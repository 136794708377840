<script setup lang="ts">
import VModal from "@magnit/core/src/components/VModal/VModal.vue";

const { isActive, isOpen, name, close } = useShareModal();
</script>

<template>
  <div class="share">
    <VModal
      :id="name"
      :active="isActive"
      :open="isOpen"
      :type="{ xs: 'bottom-sheet', m: 'modal' }"
      title="Поделиться"
      wrapper-class="share__modal"
      @close="close"
    >
      <VShareContent @close="close" />
    </VModal>
    <slot />
  </div>
</template>

<style lang="postcss" scoped>
.share {
  :global(.share__modal) {
    z-index: 100000000;

    @media (--pl-viewport-from-m) {
      display: none !important;
    }
  }
}
</style>
