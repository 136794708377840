import type { Ref } from "vue";
import type {
  IPromoProduct,
  IGoodsProduct,
} from "@magnit/unit-catalog/src/unit-catalog.types";
import type { ICatalogBFFDetailedGood } from "@magnit/unit-product-details/src/types/goods";

export default (
  product: Ref<ICatalogBFFDetailedGood | IGoodsProduct | IPromoProduct>,
) => {
  const { query } = useRoute();
  const categoriesStore = useCategoriesStore();

  const productCategories = computed(
    () => (product.value as IGoodsProduct)?.categories ?? [],
  );
  const productArticleCategory = computed(
    () => (product.value as IPromoProduct)?.articleCategory ?? null,
  );
  const productDiscountCategory = computed(
    () => (product.value as IPromoProduct)?.discountCategory ?? null,
  );
  const productCurrentCategory = computed(() => {
    const routeCategory = query.category ?? null;
    if (routeCategory) {
      const category = categoriesStore.getCategoryById(
        String(routeCategory),
        "goods",
      );
      if (category) return category;
    }
    if (productCategories.value.length) {
      for (let i = 0, len = productCategories.value.length; i < len; i += 1) {
        const single = String(productCategories.value[i]);
        const category = categoriesStore.getCategoryById(single, "goods");
        if (category) return category;
      }
    }
    if (productArticleCategory.value) {
      const category = categoriesStore.getCategoryById(
        String(productDiscountCategory.value),
        "promo",
      );
      if (category) return category;
    }
    return null;
  });

  return {
    productCurrentCategory,
  };
};
