<script setup lang="ts">
import { useBrowserLocation } from "@vueuse/core";
import VButton from "@magnit/core/src/components/VButton/VButton.vue";
import VStack from "@magnit/core/src/components/VStack/VStack.vue";
import VStackItem from "@magnit/core/src/components/VStack/VStackItem.vue";
import VkLogo from "@magnit/icons/src/assets/icons/social-vk-40.svg";
import TelegramLogo from "@magnit/icons/src/assets/icons/social-telegram-40.svg";
import OkLogo from "@magnit/icons/src/assets/icons/social-ok-40.svg";
import CopyIcon from "@magnit/icons/src/assets/icons/copy-24.svg";
import useToasts from "~/composables/useToasts";

const emit = defineEmits<{
  close: [];
}>();
const location = useBrowserLocation();
const href = computed(
  () => location.value.href && encodeURIComponent(location.value.href),
);

const onItemClick = () => {
  emit("close");
};
const onCopy = () => {
  if (!location.value.href) {
    return;
  }

  window.navigator.clipboard.writeText(location.value.href).then(() => {
    emit("close");
    const toasts = useToasts();
    toasts.success({ text: "Ссылка скопирована" });
  });
};
</script>

<template>
  <ClientOnly class="share-content">
    <VStack
      class="share-content__buttons"
      :direction="{ xs: 'column' }"
      :wrap="{ xs: 'wrap' }"
      gap="12px"
    >
      <VStackItem :size="{ xs: 12 }">
        <VStack direction="row" justify="center" gap="10px" wrap="nowrap">
          <a
            class="share-content__item"
            :href="`https://vk.com/share.php?url=${href}`"
            target="_blank"
            @click.stop="onItemClick()"
          >
            <VkLogo />
          </a>
          <a
            class="share-content__item"
            :href="`https://t.me/share/url?url=${href}`"
            target="_blank"
            @click.stop="onItemClick()"
          >
            <TelegramLogo />
          </a>
          <a
            class="share-content__item"
            :href="`https://connect.ok.ru/offer?url=${href}`"
            target="_blank"
            @click.stop="onItemClick()"
          >
            <OkLogo />
          </a>
        </VStack>
      </VStackItem>

      <VStackItem :size="{ xs: 12 }">
        <VButton icon-position="right" full-width @click.stop="onCopy">
          Скопировать ссылку
          <template #icon>
            <CopyIcon />
          </template>
        </VButton>
      </VStackItem>
    </VStack>
  </ClientOnly>
</template>

<style lang="postcss" scoped>
.share-content {
  z-index: 10000000;

  &__item {
    display: flex;

    & svg {
      width: 56px;
      height: 56px;
    }
  }
}
</style>
